export enum FieldTypes {
	TEXT_AREA = 0,
	TEXT_INPUT = 1,
	CHECKBOX = 2,
	RADIO_BUTTON = 3,
	RICHTEXT_CONTENT = 4,
}

export interface IFormField {
	id: string;
	type: FieldTypes;
	description: string;
	label: string;
	isRequired: boolean;
	isDisabled: boolean;
}

export interface RadioField extends IFormField {
	type: FieldTypes.RADIO_BUTTON;
	options: string[];
}

export interface CheckBoxField extends IFormField {
	type: FieldTypes.CHECKBOX;
	isChecked: boolean;
}

export interface TextInputField extends IFormField {
	type: FieldTypes.TEXT_INPUT;
	placeholder: string;
}

export interface TexAreaField extends IFormField {
	type: FieldTypes.TEXT_AREA;
	placeholder: string;
	maxLength: 0;
	minLength: 0;
}

export interface RichtextContent extends IFormField {
	type: FieldTypes.RICHTEXT_CONTENT;
	content: string;
}

export function fieldIsRadio(field: IFormField): field is RadioField {
	return field.type === FieldTypes.RADIO_BUTTON;
}

export function fieldIsTextArea(field: IFormField): field is TexAreaField {
	return field.type === FieldTypes.TEXT_AREA;
}

export function fieldIsTextInput(field: IFormField): field is TextInputField {
	return field.type === FieldTypes.TEXT_INPUT;
}

export function fieldIsCheckBox(field: IFormField): field is CheckBoxField {
	return field.type === FieldTypes.CHECKBOX;
}

export function fieldIsRichtextContent(
	field: IFormField
): field is RichtextContent {
	return field.type === FieldTypes.RICHTEXT_CONTENT;
}
