import { Close } from "@mui/icons-material";
import { Box, Modal as JoyModal, ModalDialog, Typography } from "@mui/joy";
import { PropsWithChildren } from "react";
import { ButtonBase } from "@mui/material";

interface ModalProps extends PropsWithChildren {
  open: boolean;
  onClose(): void;
  title?: string;
}

const BasicModal = ({ onClose, open, children, title }: ModalProps) => {
  return (
    <JoyModal
      hideBackdrop
      sx={{
        backdropFilter: "brightness(90%)",
        display: "flex",
      }}
      open={open}
    >
      <ModalDialog layout="center">
        <Box
          display="flex"
          flexDirection="row-reverse"
          justifyContent="space-between"
          alignItems="center"
        >
          <ButtonBase onClick={onClose}>
            <Close />
          </ButtonBase>

          {title ?? <Typography level="title-sm">{title}</Typography>}
        </Box>
        {children}
      </ModalDialog>
    </JoyModal>
  );
};

export default BasicModal;
