import { useCallback, useMemo } from "react";
import { IUnit } from "../../store/api/umbraco-api";
import ContentUnit from "./content-unit";
import FormUnit from "./form-unit";
import {
  isAIConversation,
  isContentUnit,
  isFormUnit,
  isMultiChoiceExercise,
  isOpenResponseExercise,
} from "./unit.types";
import ExerciseQuestion from "./exercise-question";
import OpenExerciseQuestion from "./open-exercise-question";
import AIConversationUnit from "./AIConversationUnit";

interface UnitProps {
  unit: IUnit;
  onUnitCompleteChange(t: any): void;
  setUnitFinished(finish: boolean): void;
  skip: () => void;
}

const Unit = ({
  unit,
  onUnitCompleteChange: onChange,
  setUnitFinished,
  skip
}: UnitProps) => {
  const startTime = useMemo(() => new Date().toISOString(), []);
  const setUnitComplete = useCallback(
    (extraArgs: any) => {
      onChange({
        start: startTime,
        end: new Date().toISOString(),
        id: unit.id,
        type: unit.type,
        ...extraArgs,
      });
    },
    [onChange, unit]
  );

  if (isOpenResponseExercise(unit)) {
    return (
      <OpenExerciseQuestion
        {...unit}
        key={unit.id}
        setUnitFinished={setUnitFinished}
        setUnitComplete={setUnitComplete}
        skip={skip}
      />
    );
  }

  if (isMultiChoiceExercise(unit)) {
    return (
      <ExerciseQuestion
        {...unit}
        key={unit.id}
        setUnitFinished={setUnitFinished}
        setUnitComplete={setUnitComplete}
        onChoiceChanged={() => null} //todo send res
        skip={skip}
      />
    );
  }

  if (isFormUnit(unit)) {
    return (
      <FormUnit
        setUnitFinished={setUnitFinished}
        setUnitComplete={setUnitComplete}
        unit={unit}
      />
    );
  }

  if (isContentUnit(unit)) {
    return (
      <ContentUnit
        setUnitComplete={setUnitComplete}
        setUnitFinished={setUnitFinished}
        unit={unit}
      />
    );
  }

  if (isAIConversation(unit)) {
    return (
      <AIConversationUnit
        setUnitComplete={setUnitComplete}
        setUnitFinished={setUnitFinished}
        unit={unit}
      />
    );
  }

  return null;
};

export default Unit;
