import { Box, CircularProgress, Typography } from "@mui/joy";

const LoadingIndicator = () => {
  return (
    <Box display="flex" alignItems="center"  gap={2}>
      <CircularProgress size="sm" color="primary" />
      <Typography level="h4">טוען, אנא המתן...</Typography>
    </Box>
  );
};

export default LoadingIndicator;
