import { xor } from "lodash/fp";
import { useCallback, useState } from "react";

export interface SelectionGroupProps<T, K> {
  getKey?(item: T): K;
  initialSelection?: K[];
}

const useMultipleSelection = <T, K>({
  getKey,
  initialSelection = [],
}: SelectionGroupProps<T, K>) => {
  const [selected, setSelected] = useState<K[]>(initialSelection);

  const toggleItem = useCallback(
    (item: T) =>
      setSelected(
        xor(selected, [getKey ? getKey(item) : (item as unknown as K)])
      ),
    [selected, getKey]
  );

  return { selected, toggleItem, setSelected };
};

export default useMultipleSelection;
