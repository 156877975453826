import React from "react";
import { Button } from "@mui/joy";
import { themeBase } from "../theme";

type SubmitButtonProps = {
  text: string;
  loadingText: string;
  isLoading: boolean;
  disabled: boolean;
  onClick: () => void;
};

export default function SubmitButton({
  text,
  loadingText,
  isLoading,
  disabled,
  onClick,
}: SubmitButtonProps) {
  return (
    <Button
      disabled={disabled || isLoading}
      onClick={onClick}
      sx={{
        width: "100%",
        maxWidth: 400,
        backgroundColor: themeBase.colorSchemes.new_design.palette.purple,
        color: "white",
        padding: "10px",
        fontWeight: "bold",
        fontSize: "1rem",
        borderRadius: 8,
        marginTop: 1,
        transition: "all 200ms ease-in-out", // Add transition effect
        "&:hover": {
          backgroundColor: themeBase.colorSchemes.new_design.palette.purple_dark,
          color: "lightgray",
        },
        "&:disabled": {
          backgroundColor: themeBase.colorSchemes.new_design.palette.purple,
          color: themeBase.colorSchemes.new_design.palette.white,
        },
      }}
    >
      {isLoading ? loadingText : text}
    </Button>
  );
}
