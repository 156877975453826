import { Box, LinearProgress, Typography } from "@mui/joy";
import TeacherImage from "../../images/Teacher.png";
import { AccessTime, CheckRounded } from "@mui/icons-material";
import Metric from "../../components/home_screen/metric";
import ChapterCard from "../../components/home_screen/chapter-card";
import { themeBase } from "../../theme";
import {
  useGetCoursesByCourseIdChaptersQuery,
  useGetCoursesByIdQuery,
  useGetLoginProvidersQuery,
  useGetOrganizationsQuery,
} from "../../store/api/umbraco-api";
import { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setOrganization } from "../../store/providerSlice";
import { isEmpty, some } from "lodash";
import { FeatureType } from "./components/feature-info";
import { RootState } from "../../store";

const Section = ({
  title,
  children,
}: {
  title: string;
  children: React.ReactNode;
}) => (
  <Box sx={{ mb: 4 }}>
    <Typography level="h3" sx={{ mb: 2 }}>
      {title}
    </Typography>
    {children}
  </Box>
);

const CourseScreen = () => {
  const dispatch = useDispatch();
  const selectedCourse = useSelector(
    (state: RootState) => state.selectedCourse
  );

  const { data: organizations } = useGetOrganizationsQuery();

  useEffect(() => {
    if (organizations) {
      dispatch(setOrganization(organizations));
    }
  }, [organizations, dispatch]);

  // const { data: course, isLoading } = useGetCoursesByIdQuery({
  //   id: organizations?.[0]?.courses?.[0]?.id || 0,
  // });

  // const { data: chapters = [], isLoading: isChaptersLoading } =
  //   useGetCoursesByCourseIdChaptersQuery({
  //     courseId: organizations?.[0]?.courses?.[0]?.id || 0,
  //   });

  const { data: course, isLoading } = useGetCoursesByIdQuery(
    { id: selectedCourse.id || 0 },
    { skip: !selectedCourse.id }
  );

  const { data: chapters = [], isLoading: isChaptersLoading } =
    useGetCoursesByCourseIdChaptersQuery(
      { courseId: selectedCourse.id || 0 },
      { skip: !selectedCourse.id }
    );

  const completedChaptersCount = chapters.filter(
    (chapter) =>
      (chapter.completed || 0) === (chapter.total || 0) &&
      (chapter.total || 0) > 0
  ).length;

  const inProgressChaptersCount = chapters.filter(
    (chapter) =>
      (chapter.completed || 0) > 0 &&
      (chapter.completed || 0) < (chapter.total || 0)
  ).length;

  if (isLoading || isChaptersLoading) {
    return <LinearProgress sx={{ width: "600px" }} />;
  }

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 3,
      }}
    >
      <Box
        sx={{
          display: { xs: "grid", md: "flex" }, // Use grid on smaller screens, flex on larger screens
          gridTemplateColumns: { xs: "1fr", lg: "1fr 1fr" }, // Single column for very small screens, two columns for small screens
          alignItems: "center",
          justifyContent: "space-between", // Spacing in flex mode
          gap: 2, // Spacing between grid items
        }}
      >
        <Box sx={{ display: "grid", gap: 2, width: "100%" }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between", // Spacing in flex mode
            }}
          >
            <Typography
              sx={{
                alignSelf: "center",
              }}
              level="h1"
            >
              {course?.name}
            </Typography>
            <Box
              component="img"
              src={TeacherImage}
              alt="logo"
              sx={{
                maxHeight: { xs: 200, sm: 300, xl: 350 }, // Scale the max height for different screen sizes
                width: { xs: "40%", sm: "40%" }, // Full width for small screens, auto for larger screens
                display: { md: "none" }, // Hide on small screens, show on medium and larger screens
              }}
            />
          </Box>

          <Box
            sx={{
              display: "grid", // Grid layout for metrics
              gap: 1,
              gridTemplateColumns: {
                sm: "repeat(1, 1fr)",
                md: "repeat(3, 1fr)",
              }, // Adjust columns based on screen size
            }}
          >
            <Metric
              label="פרקים שהושלמו"
              value={completedChaptersCount}
              icon={
                <CheckRounded
                  sx={{
                    color: themeBase.colorSchemes.new_design.palette.purple,
                  }}
                />
              }
            />
            <Metric
              label="פרקים בתהליך"
              value={inProgressChaptersCount}
              icon={
                <AccessTime
                  sx={{
                    color: themeBase.colorSchemes.new_design.palette.purple,
                  }}
                />
              }
            />
            {/* {course?.streak && <Metric
              label="סטאריק יומי"
              value={12}
              icon={
                <Whatshot
                  sx={{
                    color: themeBase.colorSchemes.new_design.palette.purple,
                  }}
                />
              }
            />} */}
          </Box>
        </Box>

        <Box
          component="img"
          src={TeacherImage}
          alt="logo"
          sx={{
            maxHeight: { xs: 200, sm: 300, xl: 350 }, // Scale the max height for different screen sizes
            width: { sm: "40%", md: "30%", lg: "40%" }, // Full width for small screens, auto for larger screens
            objectFit: "contain", // Maintain aspect ratio and prevent cropping
            alignSelf: { xs: "center", md: "flex-end" }, // Center on small screens, align to the right on larger screens
            display: { xs: "none", md: "block" }, // Hide on small screens, show on medium and larger screens
          }}
        />
      </Box>

      <Section title="פרקי למידה">
        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: {
              xs: "1fr",
              sm: "repeat(2, 1fr)",
              lg: "repeat(3, 1fr)",
            },
            gap: 2,
          }}
        >
          {chapters &&
            chapters.map((chapter, index) => (
              <ChapterCard
                // description={chapter.description}
                key={index}
                title={chapter.title}
                total={chapter.total}
                completed={chapter.completed}
                learningMinutes={chapter.learningMinutes}
                features={chapter.features}
              />
            ))}
        </Box>
      </Section>
    </Box>
  );
};

export default CourseScreen;
