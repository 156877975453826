import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { PersistGate } from "redux-persist/integration/react";
import { Provider } from "react-redux";
import { CssVarsProvider } from "@mui/joy";
import { persistor, store } from "./store";
import UnauthenticatedRoute from "./components/route-guards/unauthenticated-route";
import AuthenticatedRoute from "./components/route-guards/authenticated-route";
import Login from "./screens/login";
import { theme } from "./theme";
import NotFound from "./screens/not-found";
import SmartLessons from "./screens/course/course-content/smart-lessons.tsx";
import Exercises from "./screens/course/course-content/exercises";
import Signup from "./screens/signup";
import FeatureScreen from "./screens/course/feature";
import CourseScreen from "./screens/course";
import ChapterScreen from "./screens/chapter";

const queryClient = new QueryClient({});

const withOptionalProvider = (path: string, element: JSX.Element) => [
  { path, element },
  { path: `/:provider${path}`, element },
];

// Unauthenticated routes
const unauthenticatedRoutes = [
  ...withOptionalProvider("/login", <Login />),
  ...withOptionalProvider("/signup", <Signup />),
];

// Authenticated routes
const authenticatedRoutes = [
  { path: "/", element: <CourseScreen /> },
  { path: "/chapter/:chapterTitle", element: <ChapterScreen /> },
  { path: "/chapter/:chapterTitle/:featureId", element: <FeatureScreen /> },
  { path: "/:courseId/smart-lessons", element: <SmartLessons /> },
  { path: "/:courseId/exercises", element: <Exercises /> },
  // { path: "/:courseId/exercise/:featureId", element: <Feature /> },
  // { path: "/:courseId/feature/:featureId", element: <Feature /> },
  { path: "*", element: <NotFound /> },
];

// Combine into router
const router = createBrowserRouter([
  {
    element: <UnauthenticatedRoute />,
    children: unauthenticatedRoutes,
  },
  {
    element: <AuthenticatedRoute />,
    children: authenticatedRoutes,
  },
]);

function App() {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <CssVarsProvider theme={theme} />
        <QueryClientProvider client={queryClient}>
          <RouterProvider router={router} />
        </QueryClientProvider>
      </PersistGate>
    </Provider>
  );
}

export default App;
