import { Typography } from "@mui/joy";

interface ContentUnitProps {
	content: string;
}

const RichText = ({ content }: ContentUnitProps) => (
	<Typography>
		<div dangerouslySetInnerHTML={{ __html: content }} />
	</Typography>
);

export default RichText;
