import { Divider } from "@mui/joy";
import { PropsWithChildren } from "react";

const Section = ({ children }: PropsWithChildren) => {
  return (
    <>
      {children}
      <Divider sx={{ mt: 1, mb: 1, ml: -3, mr: -3 }} />
    </>
  );
};

export default Section;
