import { Typography } from "@mui/joy";
import { ContentUnit as ContentUnitType } from "./unit.types";
import { useEffect } from "react";
import RichText from "../rich-text";

interface ContentUnitProps {
  unit: ContentUnitType;
  setUnitFinished(finish: boolean): void;
  setUnitComplete(args: any): void;
}

const ContentUnit = ({
  unit: { title, content },
  setUnitFinished,
  setUnitComplete,
}: ContentUnitProps) => {
  useEffect(() => {
    setUnitFinished(true);
    setUnitComplete({});
  }, [setUnitFinished, setUnitComplete]);

  return (
    <>
      {title && <Typography>{title}</Typography>}
      <RichText content={content} />
    </>
  );
};

export default ContentUnit;
