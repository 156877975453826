import { Box, Typography, Link } from "@mui/joy";
import { themeBase } from "../../theme";

const Footer: React.FC = () => {
  return (
    <Box
      sx={{
        backgroundColor: themeBase.colorSchemes.new_design.palette.white,
        paddingX: {
          xs: "6px",
          sm: "20px",
          md: "80px",
    
        },
        paddingY: "20px",
        borderTop: `1px solid ${themeBase.colorSchemes.new_design.palette.gray_light}`,
        textAlign: "center",
      }}
    >
      <Typography
        level="body-sm"
        sx={{
          color: themeBase.colorSchemes.new_design.palette.gray,
          marginBottom: "8px",
        }}
      >
        © {new Date().getFullYear()} All rights reserved to Tauga AI
      </Typography>
      {/* <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          gap: 2,
        }}
      >
       
        <Link
          href="/terms"
          sx={{
            color: themeBase.colorSchemes.new_design.palette.gray,
            textDecoration: "none",
          }}
        >
          Terms of Service
        </Link>
        <Link
          href="/contact"
          sx={{
            color: themeBase.colorSchemes.new_design.palette.gray,
            textDecoration: "none",
          }}
        >
          Contact Us
        </Link>
      </Box> */}
    </Box>
  );
};

export default Footer;
