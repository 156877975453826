import { Box, Button, Input } from "../components";
import { umbracoApi, usePostAuthLoginMutation } from "../store/api/umbraco-api";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { login } from "../store/session/actions/user.actions";
import LogoBox from "../components/header/logo-box";
import { CircularProgress, Typography } from "@mui/joy";
import { get } from "lodash/fp";
import { TAUGA_AI_LOGO_1 } from "../images/images";
import useNavigateWithProvider from "../hooks/useNavigateWithProvider";
import LoginImage from "../images/Login_Image.png";
import { themeBase } from "../theme";
import SubmitButton from "../components/buttons";

const Login = () => {
  const dispatch = useDispatch();
  const [mutate, { isSuccess, isLoading, data, isError }] =
    usePostAuthLoginMutation();
  const [fetchOrgs, { data: orgsData, isLoading: isLoadingOrgs }] =
    umbracoApi.useLazyGetOrganizationsQuery();
  const [password, setPassword] = useState("");
  const [email, setEmail] = useState("");
  const { provider } = useParams(); // Get the provider from the URL params
  const [logoToDisplay, setLogoToDisplay] = useState<string[] | undefined>(); // State for dynamic logo
  const [fetchLoginProvider, { data: loginProvider }] =
    umbracoApi.useLazyGetLoginProvidersByProviderUrlNameQuery();

  // fetch the login provider
  useEffect(() => {
    if (provider) {
      const normalizedProvider = provider.toLowerCase();
      fetchLoginProvider({ providerUrlName: normalizedProvider }, true);
    }
  }, [provider, fetchLoginProvider]);

  const navigateWithProvider = useNavigateWithProvider();

  // set the second logo when login provider is loaded
  useEffect(() => {
    setLogoToDisplay(loginProvider?.logoUrl);
  }, [loginProvider]);

  const onLogin = () => {
    mutate({
      loginRequest: {
        username: email,
        keepMeLoggedIn: true,
        password,
      },
    });
  };

  useEffect(() => {
    if (isSuccess && data) {
      const { email, firstName, lastName } = data;

      if (firstName && email && lastName) {
        console.log("data");

        dispatch(
          login({
            email,
            firstName,
            lastName,
            isTeacher: get("isTeacher", data) || false,
          })
        );

        fetchOrgs();
      }
    }
  }, [data, dispatch, isSuccess, fetchOrgs]);

  return (
    <Box
      sx={{
        display: "flex",
        height: "100%",
        width: "100%",
        backgroundColor: "#f5f5ff", // Light purple background
        justifyContent: "space-evenly",
        flexWrap: "wrap",
      }}
    >
      {/* Left: Login Card */}
      <Box
        sx={{
          backgroundColor: themeBase.colorSchemes.new_design.palette.white,
          borderWidth: 1,
          borderColor: themeBase.colorSchemes.new_design.palette.gray_light,
          borderStyle: "solid", // Add this to make the border visible
          height: { xs: "100%", lg: "80%" },
          padding: 4,
          borderBottomLeftRadius: 50,
          borderBottomRightRadius: 50,
          width: { xs: "100%", lg: "30%" },
          display: "flex",
          flexDirection: "column",
          justifyContent: "center", // Centers content vertically
          alignItems: "center", // Centers content horizontally
          gap: 2, // Adds consistent spacing between items
        }}
      >
        {/* Logo and Welcome Text */}
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            textAlign: "center",
            marginBottom: 4, // Slightly larger spacing before input fields
          }}
        >
          <LogoBox imageSrc={TAUGA_AI_LOGO_1} imageHeight={60} />
          <Typography level="h1" sx={{ marginTop: 2, fontWeight: "bold" }}>
            ברוכים הבאים לטאוגה
          </Typography>
          <Typography
            level="body-lg"
            sx={{
              marginTop: 1,
              color: "gray",
              maxWidth: 400,
              lineHeight: 1.5,
            }}
          >
            עם טאוגה, תוכל ללמוד קורסים שונים בהתאמה לרמה שלך. החומר מתעדכן בזמן
            אמת כדי שתוכל להתקדם{" "}
            <Typography
              component="span"
              sx={{
                color: themeBase.colorSchemes.new_design.palette.purple, // Purple color for "בקצב שלך"
                fontWeight: "bold", // Optional: Make it bold for emphasis
              }}
            >
              {" "}
              בקצב שלך!{" "}
            </Typography>
          </Typography>
        </Box>

        {/* Input Fields */}
        <Box
          sx={{
            width: "100%",
            maxWidth: 400, // Ensures inputs are not too wide
            display: "flex",
            flexDirection: "column",
            gap: 1, // Adds consistent spacing between inputs
          }}
        >
          <Input
            disabled={isLoading}
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="שם משתמש"
            sx={{
              backgroundColor:
                themeBase.colorSchemes.new_design.palette.gray_light,
              width: "100%",
              maxWidth: 400,
              height: 50,
              border: "none !important", // Override default
              borderRadius: 1,
              fontSize: "1rem",
              "& fieldset": {
                border: "none", // Removes border from 'outlined' variant
              },
            }}
          />
          <Input
            disabled={isLoading}
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            type="password"
            placeholder="סיסמה"
            sx={{
              backgroundColor:
                themeBase.colorSchemes.new_design.palette.gray_light,
              width: "100%",
              maxWidth: 400,
              height: 50,
              border: "none !important", // Override default
              borderRadius: 1,
              fontSize: "1rem",
              "& fieldset": {
                border: "none", // Removes border from 'outlined' variant
              },
            }}
          />
        </Box>

        {/* Login Button */}
        <SubmitButton
          disabled={!email || !password || isLoading}
          text="התחברות"
          loadingText="מתחבר..."
          isLoading={isLoading}
          onClick={onLogin}
        />

        {/* Error Message */}
        {isError && (
          <Typography
            textAlign="center"
            sx={{
              marginTop: 2,
              color: themeBase.colorSchemes.new_design.palette.black,
              fontSize: "0.9rem",
            }}
          >
            שם המשתמש או הסיסמה שגויים, אנא נסה שוב.
          </Typography>
        )}

        {/* Sign-Up Link */}
      </Box>

      {/* Right: Illustration */}
      <Box
        component="img"
        src={LoginImage}
        alt="Login illustration"
        sx={{
          justifySelf: "center",
          alignSelf: "center",
          objectFit: "cover",
          display: { xs: "none", lg: "block" },
        }}
      />
    </Box>
  );
};

export default Login;
