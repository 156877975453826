import {
  AutoAwesome,
  CheckCircleOutlineRounded,
  Edit,
  FilterAlt,
  Lock,
  School,
} from "@mui/icons-material";
import { Box, Divider, Tooltip, Typography } from "@mui/joy";
import ButtonBase from "@mui/material/ButtonBase";
import { Feature } from "../../../store/api/umbraco-api";
import { themeBase } from "../../../theme";

export enum FeatureType {
  Exercise = 0,
  Lesson = 1,
  SmartLesson = 2,
  FilterExercise = 3,
}

export const FeatureIcon = ({
  type,
  htmlColor = themeBase.colorSchemes.light.palette.primary[200],
}: {
  type?: FeatureType;
  htmlColor: string;
}) => {
  if (type === FeatureType.Exercise) {
    return (
      <Edit sx={{ pt: 0.5, ml: 1 }} htmlColor={htmlColor} fontSize="small" />
    );
  }

  if (type === FeatureType.FilterExercise) {
    return (
      <FilterAlt
        sx={{ pt: 0.5, ml: 1 }}
        htmlColor={htmlColor}
        fontSize="small"
      />
    );
  }

  if (type === FeatureType.SmartLesson) {
    return (
      <AutoAwesome
        sx={{ pt: 0.5, ml: 1 }}
        htmlColor={htmlColor}
        fontSize="small"
      />
    );
  }

  return (
    <School sx={{ pt: 0.5, ml: 1 }} htmlColor={htmlColor} fontSize="small" />
  );
};

interface FeatureProps extends Omit<Feature, "type"> {
  courseId: string | undefined;
  description?: string;
  onClick?(): void;
  type?: FeatureType | string;
}

export const RenderFeature = ({
  name,
  isLocked,
  type,
  description,
  onClick,
  status,
}: FeatureProps) => {
  return (
    <Box sx={{ gap: 2 }} display="flex" flexDirection="row" alignItems="center">
      <Box
        sx={{ gap: 2, width: "600px" }}
        display="flex"
        flexDirection="column"
      >
        <ButtonBase
          disabled={isLocked}
          onClick={onClick}
          sx={
            {
              // maxWidth: "600px",
            }
          }
        >
          <Box
            color={!isLocked ? "primary" : "neutral"}
            sx={{
              display: "flex",
              flexDirection: "row",
              width: "100%",
              textAlign: "start",
              maxWidth: "600px",
            }}
          >
            <FeatureIcon
              type={type as FeatureType}
              htmlColor={
                !isLocked
                  ? themeBase.colorSchemes.light.palette.primary[500]
                  : "gray"
              }
            />
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
              }}
            >
              <Typography textColor={!isLocked ? "black" : "gray"}>
                {name}
              </Typography>

              <Typography level="body-xs" color="neutral">
                {description}
              </Typography>
            </Box>
          </Box>
        </ButtonBase>
        <Divider />
      </Box>
      {isLocked && (
        <Tooltip arrow placement="left" title="התוכן תלוי בסיום השלבים האחרים">
          <Lock color="disabled" />
        </Tooltip>
      )}
      {(status as unknown) === 2 && (
        <Tooltip arrow placement="left" title="סיימת את הפרק">
          <CheckCircleOutlineRounded
            htmlColor={themeBase.colorSchemes.light.palette.primary[500]}
          />
        </Tooltip>
      )}
    </Box>
  );
};
