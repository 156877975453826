import React, { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import { useSelector } from "react-redux";
import { Box, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import Header from "../header";
import SideBar from "../sidebar";
import { userSelector } from "../../store/session/selectors";
import useNavigateWithProvider from "../../hooks/useNavigateWithProvider";
import { themeBase } from "../../theme";
import Footer from "../footer";

const AuthenticatedRoute: React.FC = () => {
  const user = useSelector(userSelector);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const theme = useTheme();
  const isLargeScreen = useMediaQuery(theme.breakpoints.up("md"));

  const handleToggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const navigateWithProvider = useNavigateWithProvider();

  useEffect(() => {
    if (!user) {
      navigateWithProvider("/login");
    }
  }, [user]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "100vh", // Ensure the parent container takes the full height
        bgcolor: themeBase.colorSchemes.new_design.palette.background,
      }}
    >
      <Header onToggleSidebar={handleToggleSidebar} />

      <Box
        sx={{
          display: "flex",
          flex: 1, // Ensures this section takes up all remaining space
          overflow: "hidden", // Prevents scrolling on the entire container
        }}
      >
        {isLargeScreen ? (
          <Box
            component="main"
            sx={{
              flex: 1, // Ensures the main content stretches
              overflowY: "auto", // Enables vertical scrolling for the content
              paddingX: {
                xs: "6px",
                sm: "20px",
                md: "80px",

              },
              paddingY: { xs: "6px", sm: "50px" },
            }}
          >
            <Outlet />
          </Box>
        ) : (
          <>
            <Box
              component="main"
              sx={{
                flex: 1,
                overflowY: "auto",
                paddingX: {
                  xs: "6px",
                  sm: "20px",
                  md: "80px",

                },
                paddingY: { xs: "6px", sm: "50px" },
              }}
            >
              <Outlet />
            </Box>
            <SideBar
              mobileOpen={isSidebarOpen}
              handleDrawerToggle={handleToggleSidebar}
            />
          </>
        )}
      </Box>
      <Footer />
    </Box>
  );
};

export default AuthenticatedRoute;
