import React from "react";
import { Box, Typography, Drawer, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import {
  Feature,
  IUnit,
  Unit as SidebarUnit,
} from "../../../store/api/umbraco-api";
import {
  isAIConversation,
  isContentUnit,
  isFormUnit,
  isMultiChoiceExercise,
  isOpenResponseExercise,
} from "../../../components/feature-units/unit.types";
import { theme, themeBase } from "../../../theme";
import { FaHamburger } from "react-icons/fa";
import {
  ArrowLeft,
  ArrowRight,
  ArrowRightOutlined,
  ViewSidebar,
} from "@mui/icons-material";

interface UnitsListProps {
  units: SidebarUnit[];
  unitsCompleted: number;
  unitsTotal: number;
  uniteId: number;
  featureTitle: string;
  feature: Feature;
  isMobile: Boolean;
}

const getIconForUnit = (unit: IUnit): string => {
  if (isFormUnit(unit)) return "📝"; // Clipboard
  if (isContentUnit(unit)) return "📘"; // Blue book for content
  if (isOpenResponseExercise(unit)) return "✒️"; // Fountain pen for writing
  if (isMultiChoiceExercise(unit)) return "🔢"; // Numbered for multiple choice
  if (isAIConversation(unit)) return "🤖"; // Robot for AI conversation
  return "📦"; // Package for default
};

const UnitsList: React.FC<UnitsListProps> = ({
  units,
  unitsCompleted,
  unitsTotal,
  uniteId,
  featureTitle,
  feature,
  isMobile,
}) => {
  const [isDrawerOpen, setIsDrawerOpen] = React.useState(false);

  const renderUnit = (unit: SidebarUnit) => {
    if (!unit.id) {
      console.warn("Unit ID is undefined:", unit);
      return null; // Safely handle undefined unit.id
    }

    const isCurrent = unit.id === uniteId;
    const isCompleted = unit.id < uniteId;

    return (
      <Box
        key={unit.id}
        sx={{
          display: "flex",
          alignItems: "center",
          backgroundColor: isCurrent
            ? "#E3F2FD" // Light blue for current unit
            : isCompleted
            ? "#E8F5E9" // Light green for completed units
            : "#F9FAFB", // Default for remaining units
          gap: 1,
          borderRadius: "12px",
          padding: "12px 16px",
          overflowY: "auto",
          border: "1px solid #E5E7EB",
        }}
      >
        <Typography
          sx={{ fontSize: "18px", color: "#6B7280", fontWeight: "bold" }}
        >
          {getIconForUnit(unit)}
        </Typography>
        <Typography
          sx={{
            fontSize: "16px",
            color: isCompleted ? "#2E7D32" : "#374151", // Dark green for completed, dark gray otherwise
          }}
        >
          {unit.name}
        </Typography>
      </Box>
    );
  };

  const unitsListContent = (
    <Box
      sx={{
        width: isMobile ? 300 : "auto",
        backgroundColor: "#FFFFFF",
        borderRadius: "16px",
        border: "1px solid #E5E7EB",
        padding: 2,
        overflowY: "auto", // Enable scrolling
      }}
    >
      {/* Header Section */}
      <Box sx={{ padding: "16px", borderBottom: "1px solid #E5E7EB" }}>
        <Typography
          sx={{
            fontWeight: "bold",
            fontSize: "20px",
            marginBottom: "8px",
            color: "#333333",
          }}
        >
          {featureTitle}
        </Typography>
        <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
          <Typography
            sx={{
              fontSize: "0.75rem",
              color: themeBase.colorSchemes.new_design.palette.purple,
            }}
          >
            {unitsCompleted}/{unitsTotal}
          </Typography>
          <Box
            sx={{
              height: "10px",
              backgroundColor:
                themeBase.colorSchemes.new_design.palette.purple_light,
              borderRadius: "5px",
              overflow: "hidden",
            }}
          >
            <Box
              sx={{
                width: `${
                  unitsTotal > 0 ? (unitsCompleted / unitsTotal) * 100 : 0
                }%`, // Fixed here
                height: "100%",
                backgroundColor:
                  themeBase.colorSchemes.new_design.palette.purple,
                transition: "width 0.3s ease-in-out",
              }}
            />
          </Box>
        </Box>
      </Box>

      {/* Units List */}
      <Box
        sx={{
          padding: "16px",
          display: "flex",
          flexDirection: "column",
          gap: "12px",
          // maxHeight: "400px", // Adjust the max height as needed
        }}
      >
        {units.map((unit) => renderUnit(unit))}
      </Box>
    </Box>
  );

  return isMobile ? (
    <>
      <Box
        onClick={() => setIsDrawerOpen(true)}
        sx={{
          height: "100%",
          backgroundColor: themeBase.colorSchemes.new_design.palette.white,
          borderRadius: 2,
          borderWidth: 1,
          borderColor: themeBase.colorSchemes.new_design.palette.gray_light,
          borderStyle: "solid",
          justifyContent: "center",
          justifyItems: "center",
          display: "grid",
        }}
      >
        <IconButton
          sx={{
            borderRadius: 0,

            color: themeBase.colorSchemes.new_design.palette.purple,
          }}
        >
          <ArrowLeft
            sx={{
              borderRadius: 0,
              fontSize: "40px", // Adjust the font size as needed
            }}
          />
        </IconButton>
      </Box>

      <Drawer
        anchor="right"
        open={isDrawerOpen}
        onClose={() => setIsDrawerOpen(false)}
      >
        <Box sx={{ padding: 2 }}>
          <IconButton
            onClick={() => setIsDrawerOpen(false)}
            sx={{ marginBottom: 2 }}
          >
            <CloseIcon />
          </IconButton>
          {unitsListContent}
        </Box>
      </Drawer>
    </>
  ) : (
    unitsListContent
  );
};

export default UnitsList;
