import React, { useCallback, useEffect, useState } from "react";
import { MuiMarkdown } from "mui-markdown";
import { Box, Typography, TextField, Button } from "@mui/material";
import { Send } from "@mui/icons-material";
import {
  usePostChatMutation,
  usePostChatByConversationIdMessagesMutation,
} from "../../store/api/umbraco-api";
import { debounce } from "lodash/fp";
import { themeBase } from "../../theme";

import Assistant_Image from "../../images/Asistant.png";
import ChatLoadingSkeleton from "./skeleton";

interface ChatProps {
  resourceId: number;
}

interface Message {
  sender: string;
  text: string;
}

const Chat = ({ resourceId }: ChatProps) => {
  const [messages, setMessages] = useState<Array<Message>>([]);
  const [input, setInput] = useState<string>("");
  const [conversationId, setConversationId] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState<boolean | null>(true);
  const [isChatAvailable, setIsChatAvailable] = useState<boolean | null>(false);
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    if (isChatAvailable) {
      requestAnimationFrame(() => setIsVisible(true));
    }
  }, [isChatAvailable]);

  const [postChat] = usePostChatMutation();
  const [postChatByConversationIdMessages] =
    usePostChatByConversationIdMessagesMutation();

  const setup = async () => {
    try {
      setIsLoading(true);
      const response = await postChat({
        createConversationRequest: {
          initialMessage: "שלום",
          resourceId,
        },
      }).unwrap();

      setConversationId(response.conversationId || null);
      setMessages([
        { sender: "AI", text: response.message || "ברוך הבא לשיחה!" },
      ]);

      // Chat is available if conversationId is set successfully
      if (response.conversationId) {
        setIsChatAvailable(true);
      }
    } catch (err) {
      console.error("Error in setup:", err);

      // Handle chat unavailability here if needed
      setIsChatAvailable(false);
    } finally {
      setIsLoading(false);
    }
  };

  const debouncedSetup = useCallback(debounce(500, setup), [resourceId]);

  useEffect(() => {
    if (resourceId) debouncedSetup();
  }, [resourceId, debouncedSetup]);

  const handleSendMessage = async () => {
    if (!input.trim()) return;

    const userMessage = { sender: "User", text: input };
    setMessages((prevMessages) => [...prevMessages, userMessage]);
    setInput("");

    try {
      if (!conversationId) {
        const response = await postChat({
          createConversationRequest: { initialMessage: input, resourceId },
        }).unwrap();

        setConversationId(response.conversationId || null);
        setMessages((prevMessages) => [
          ...prevMessages,
          { sender: "AI", text: response.message || "" },
        ]);

        if (response.conversationId) {
          setIsChatAvailable(true);
        }
      } else {
        const response = await postChatByConversationIdMessages({
          conversationId,
          createMessageRequest: { message: input },
        }).unwrap();

        setMessages((prevMessages) => [
          ...prevMessages,
          { sender: "AI", text: response.message || "" },
        ]);
      }
    } catch (err) {
      console.error("Error in message sending:", err);

      // If there's an error, chat might still be available based on context
      setIsChatAvailable(!!conversationId);
    }
  };

  if (!isChatAvailable) return null;
  if (isLoading) return <ChatLoadingSkeleton />;

  return (
    <Box
      sx={{
        flex: 1,
        height: "100%",
        display: "flex",
        flexDirection: "column",
        backgroundColor: themeBase.colorSchemes.new_design.palette.white,
        borderRadius: "12px",
        maxWidth: "30%",
        borderWidth: 1,
        borderColor: themeBase.colorSchemes.new_design.palette.gray_light,
        borderStyle: "solid",
        overflow: "hidden",
        transform: isVisible ? "translateX(0)" : "translateX(-100%)",
        transition: "transform 0.3s ease-in-out",
        willChange: "transform",
      }}
    >
      {/* Header */}
      <Box
        sx={{
          padding: "12px",
          borderBottom: "1px solid #e0e0e0",
          display: "flex",
          alignItems: "center",
          gap: 2,
        }}
      >
        <img
          src={Assistant_Image} // Replace with the correct path to your image
          alt="Assistant Teacher"
          style={{ width: "50px", height: "50px", borderRadius: "50%" }}
        />
        <Box>
          <Typography variant="h6" sx={{ fontWeight: "bold" }}>
            רוני
          </Typography>
          <Typography variant="subtitle2" color="textSecondary">
            היעזר ברוני, עוזרת ההוראה שלך!
          </Typography>
        </Box>
      </Box>

      <Box
        sx={{
          flex: 1,
          overflowY: "auto",
          padding: "16px",
          display: "flex",
          flexDirection: "column",
          backgroundColor:
            themeBase.colorSchemes.new_design.palette.gray_extra_light,
        }}
      >
        {messages.map((message, index) => (
          <Box
            key={index}
            sx={{
              display: "grid",
              justifySelf: message.sender === "AI" ? "flex-end" : "flex-start",
              color:
                message.sender === "User"
                  ? themeBase.colorSchemes.new_design.palette.purple
                  : themeBase.colorSchemes.new_design.palette.white,
              borderRadius: "12px",
              backgroundColor:
                message.sender === "User"
                  ? themeBase.colorSchemes.new_design.palette.purple_light
                  : themeBase.colorSchemes.new_design.palette.purple,
              padding: "10px 16px",
              wordBreak: "break-word",
              marginY: 1,
            }}
          >
            <Typography variant="body2">
              <MuiMarkdown>{message.text}</MuiMarkdown>
            </Typography>
          </Box>
        ))}
      </Box>

      {/* Input Box */}
      <Box
        sx={{
          bg: "#fff",
          py: 2,
          px: 1,
          borderTop: "1px solid #e0e0e0",
          display: "flex",
          alignItems: "center",
          gap: 1,
        }}
      >
        <TextField
          fullWidth
          value={input}
          onChange={(e) => setInput(e.target.value)}
          onKeyDown={(e) => e.key === "Enter" && handleSendMessage()}
          placeholder="כתוב הודעה..."
          variant="standard"
          InputProps={{
            disableUnderline: true,
          }}
          sx={{}}
        />
        <Button
          onClick={handleSendMessage}
          sx={{
            color: themeBase.colorSchemes.new_design.palette.purple,
            backgroundColor: themeBase.colorSchemes.new_design.palette.white,
            ":hover": {
              backgroundColor:
                themeBase.colorSchemes.new_design.palette.purple_light,
            },
          }}
        >
          <Send
            sx={{
              transform: "rotate(180deg)",
            }}
          />
        </Button>
      </Box>
    </Box>
  );
};

export default Chat;
