export const REACT_APP_SERVER_DOMAIN =
  process.env.REACT_APP_SERVER_DOMAIN || "https://localhost:44375";

console.log('REACT_APP_SERVER_DOMAIN:',REACT_APP_SERVER_DOMAIN)
console.log(' process.env.NODE_ENV:', process.env.NODE_ENV)


// const PROD_BASE_URL = "https://tauga-demo.azurewebsites.net/";
// // const PROD_BASE_URL = "https://api.tauga.ai/";

// const DEV_BASE_URL = "https://localhost:44375";

// export const REACT_APP_SERVER_DOMAIN =
// 	process.env.NODE_ENV === "production" ? PROD_BASE_URL : DEV_BASE_URL;
