import { Typography } from "@mui/joy";
import { ButtonBase } from "@mui/material";
import { ReactElement } from "react";

interface RenderCourseProps {
  checked: boolean;
  text: string;
  onClick?(): void;
  Icon?: ReactElement;
}

const SideBarNavButton = ({
  text,
  checked,
  onClick,
  Icon,
}: RenderCourseProps) => {
  return (
    <ButtonBase
      sx={{
        mr: -1,
        p: 1,
        padding: 2,
        display: "flex",
        flexGrow: 1,
        alignSelf: "stretch",
        justifyContent: "stretch",
        maxHeight: "25px",
        minHeight: "25px",
        bgcolor: checked ? "lightGray" : undefined,
        borderRadius: "5px",
        transition: "background-color 0.3s ease", // Add smooth transition
        "&:hover": {
          bgcolor: "lightGray", // Add background color on hover
        },
      }}
      onClick={onClick}
    >
      {Icon}
      <Typography textColor={"common.black"} level="body-sm">
        {text}
      </Typography>
    </ButtonBase>
  );
};

export default SideBarNavButton;
