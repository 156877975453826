import { Box } from "..";
import Typography from "../typography";
import { themeBase } from "../../theme";
import { Chapter, Feature } from "../../store/api/umbraco-api";
import { Link } from "react-router-dom";

interface ChapterCardProps {
  title?: string;
  total?: number;
  completed?: number;
  learningMinutes?: number;
  features?: Feature[];
}

const ChapterCard = ({
  title = "Untitled",
  // description = "",
  total = 0,
  completed = 0,
  learningMinutes = 0,
  features = [],
}: ChapterCardProps) => {
  return (
    <Link
      style={{
        textDecoration: "none",
      }}
      state={{
        features,
      }}
      to={`chapter/${title}/${features[0].id}`}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 2,
          p: 3,
          borderRadius: "12px",
          bgcolor: themeBase.colorSchemes.new_design.palette.white,
          transition: "box-shadow 0.3s ease-in-out",
          "&:hover": {
            boxShadow: "0px 8px 16px rgba(0, 0, 0, 0.2)", // Stronger depth
          },
        }}
      >
        {/* Title */}
        <Typography
          sx={{
            fontSize: "1.25rem",
            fontWeight: 700,
            color: themeBase.colorSchemes.new_design.palette.black,
          }}
        >
          {title}
        </Typography>

        {/* <Typography
          sx={{
            fontSize: "0.875rem",
            color: themeBase.colorSchemes.new_design.palette.gray,
          }}
        >
          {description}
        </Typography> */}

        {/* <Typography
          sx={{
            fontSize: "0.875rem",
            color: themeBase.colorSchemes.new_design.palette.gray,
          }}
        >
          זמן למידה: {learningMinutes}
        </Typography> */}

        {/* Progress Bar */}
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 1,
          }}
        >
          <Typography
            sx={{
              fontSize: "0.75rem",
              color: themeBase.colorSchemes.new_design.palette.purple,
            }}
          >
            {completed}/{total}
          </Typography>
          <Box
            sx={{
              height: "10px",
              backgroundColor:
                themeBase.colorSchemes.new_design.palette.purple_light,
              borderRadius: "5px",
              overflow: "hidden",
            }}
          >
            <Box
              sx={{
                width: `${total > 0 ? (completed / total) * 100 : 0}%`,
                height: "100%",
                backgroundColor:
                  themeBase.colorSchemes.new_design.palette.purple,
                transition: "width 0.3s ease-in-out",
              }}
            />
          </Box>
        </Box>
      </Box>
    </Link>
  );
};

export default ChapterCard;
