import { useNavigate, useParams } from "react-router-dom";

const useNavigateWithProvider = () => {
  const navigate = useNavigate();
  const { urlProvider } = useParams();

  const navigateWithProvider = (path: string, options = {}) => {
    const newPath = urlProvider ? `/${urlProvider}${path}` : path;
    navigate(newPath, options);
  };

  return navigateWithProvider;
};

export default useNavigateWithProvider;
