import { Icon as MUIIcon, SvgIconTypeMap } from "@mui/material";
import { OverridableComponent } from "@mui/material/OverridableComponent";

const Icon = MUIIcon;

export type IconComponentProp = OverridableComponent<
  SvgIconTypeMap<{}, "svg">
> & {
  muiName: string;
};

export default Icon;
