import { RootState } from "../..";
import { createSelector } from "@reduxjs/toolkit";
import { LoginProviderResponse } from "../../api/umbraco-api";

export const userSelector = createSelector(
  [(state: RootState) => state.session],
  (state) => state.user
);

export const currentOrganizationSelector = createSelector(
  [(state: RootState) => state.session],
  (state) => state.currentOrganization
);

export const providerSelector = createSelector(
  [(state: RootState) => state.provider],
  (providerState): LoginProviderResponse | null => providerState.provider
);
