import { useNavigate, useParams } from "react-router-dom";
import { Box } from "../../../components";

import { LinearProgress, Typography } from "@mui/joy";
import { useGetCoursesByIdQuery } from "../../../store/api/umbraco-api";
import { FetchBaseQueryError } from "@reduxjs/toolkit/query";
import { FeatureType, RenderFeature } from "../components/feature-info";
import { useState } from "react";

import ExerciseFilterPicker from "../components/exercise-filter-picker";
import BasicModal from "../../../components/basic-modal";
import useNavigateWithProvider from "../../../hooks/useNavigateWithProvider";

const featureNavigation = (
  type?: FeatureType,
  courseId?: string,
  featureId?: number
) => {
  const string = `/course/${courseId}`;

  switch (type) {
    case FeatureType.Exercise:
      return string + `/exercise/${featureId}`;
    default:
      return string + `/feature/${featureId}`;
  }
};

const SmartLessons = () => {
  const navigateWithProvider = useNavigateWithProvider();
  const [filterModalOpen, setFilterModalOpen] = useState(false);
  const { courseId = "" } = useParams();
  const {
    data: course,
    isError,
    error,
    isLoading,
  } = useGetCoursesByIdQuery({
    id: parseInt(courseId || "0"),
  });

  if (isError) {
    if ((error as FetchBaseQueryError)?.status === 404) {
      navigateWithProvider("/404");
    }
  }

  if (isLoading) {
    return <LinearProgress sx={{ width: "600px" }} />;
  }

  return (
    <Box
      display="flex"
      flexDirection="column"
      flexShrink={1}
      sx={{ gap: 2, maxWidth: "600px" }}
    >
      <Box>
        <Typography level="h1">שיעורים חכמים</Typography>
      </Box>

      <BasicModal
        title="תרגול לפי נושאים"
        open={filterModalOpen}
        onClose={() => setFilterModalOpen(false)}
      >
        <ExerciseFilterPicker />
      </BasicModal>

      {course?.features
        ?.filter(({ type }) => (type as unknown) === FeatureType.SmartLesson || (type as unknown) === FeatureType.Lesson)
        .map((feature, index) => (
          <RenderFeature
            {...feature}
            key={feature.id}
            courseId={courseId}
            onClick={() =>
              navigateWithProvider(
                featureNavigation(
                  feature.type as unknown as FeatureType,
                  courseId,
                  feature.id
                )
              )
            }
          />
        ))}
    </Box>
  );
};

export default SmartLessons;
