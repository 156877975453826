import { Box } from "@mui/joy";

interface ResponsiveLogoBoxProps {
  imageSrc: string;
}

const ResponsiveLogoBox = ({ imageSrc }: ResponsiveLogoBoxProps) => {
  return (
    <Box
      component="img"
      src={imageSrc}
      alt="logo"
      sx={{
        // width: { xs: "100px", sm: "100px", md: "100px" }, // Adjusts size based on screen size
        width: "100px",
        height: "auto",
        objectFit: "contain",
      }}
    />
  );
};

export default ResponsiveLogoBox;
