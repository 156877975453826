// store/providerSlice.js
import { createSlice } from "@reduxjs/toolkit";

const providerSlice = createSlice({
  name: "provider",
  initialState: { provider: null },
  reducers: {
    setOrganization(state, action) {
      state.provider = action.payload;
    },
    clearOrganization(state) {
      state.provider = null;
    },
  },
});

export const { setOrganization, clearOrganization } = providerSlice.actions;
export default providerSlice.reducer;
