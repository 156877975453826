import { Box, Typography } from "@mui/joy";

const NotFound = () => {
  return (
    <Box>
      <Typography level="h3" color="warning">
        404
      </Typography>
      <Typography>לא מצאנו את מה שביקשת</Typography>
    </Box>
  );
};

export default NotFound;
