import React from "react";
import { Box, Typography } from "@mui/material";
import { keyframes } from "@mui/system";
import Assistant_Image from '../../images/Asistant.png'
const pulseAnimation = keyframes`
  0% {
    background-color: #e0e0e0;
  }
  50% {
    background-color: #f5f5f5;
  }
  100% {
    background-color: #e0e0e0;
  }
`;

const ChatLoadingSkeleton: React.FC = () => {
  return (
    <Box
      sx={{
        flex: 1,
        height: "100%",
        display: "flex",
        flexDirection: "column",
        backgroundColor: "#fff",
        borderRadius: "12px",
        maxWidth: "30%",
        borderWidth: 1,
        borderColor: "#e0e0e0",
        borderStyle: "solid",
        overflow: "hidden",
      }}
    >
      {/* Header Skeleton */}
      <Box
        sx={{
          padding: "12px",
          borderBottom: "1px solid #e0e0e0",
          display: "flex",
          alignItems: "center",
          gap: 2,
        }}
      >
        <img
          src={Assistant_Image} // Replace with the correct path to your image
          alt="Assistant Teacher"
          style={{ width: "50px", height: "50px", borderRadius: "50%" }}
        />
        <Box>
          <Typography variant="h6" sx={{ fontWeight: "bold" }}>
            רוני
          </Typography>
          <Typography variant="subtitle2" color="textSecondary">
            היעזר ברוני, עוזרת ההוראה שלך!
          </Typography>
        </Box>
      </Box>

      {/* Messages Skeleton */}
      <Box
        sx={{
          flex: 1,
          overflowY: "auto",
          padding: "16px",
          display: "flex",
          flexDirection: "column",
          gap: "8px",
          backgroundColor: "#f9f9f9",
        }}
      >
        {[1, 2, 3].map((_, idx) => (
          <Box
            key={idx}
            sx={{
              width: idx % 2 === 0 ? "80%" : "60%",
              height: "20px",
              borderRadius: "12px",
              animation: `${pulseAnimation} 1.5s infinite ease-in-out`,
              alignSelf: idx % 2 === 0 ? "flex-end" : "flex-start",
            }}
          />
        ))}
      </Box>

      {/* Input Skeleton */}
      <Box
        sx={{
          py: 2,
          px: 1,
          borderTop: "1px solid #e0e0e0",
          display: "flex",
          alignItems: "center",
          gap: 1,
        }}
      >
        <Box
          sx={{
            flex: 1,
            height: "40px",
            borderRadius: "8px",
            animation: `${pulseAnimation} 1.5s infinite ease-in-out`,
          }}
        />
        <Box
          sx={{
            width: "40px",
            height: "40px",
            borderRadius: "8px",
            animation: `${pulseAnimation} 1.5s infinite ease-in-out`,
          }}
        />
      </Box>
    </Box>
  );
};

export default ChatLoadingSkeleton;
