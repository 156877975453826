import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface SelectedCourseState {
  id: number | null;
  name: string | null;
}

const initialState: SelectedCourseState = {
  id: null,
  name: null,
};

const courseSlice = createSlice({
  name: "selectedCourse",
  initialState,
  reducers: {
    setSelectedCourse(state, action: PayloadAction<{ id: number; name: string }>) {
      state.id = action.payload.id;
      state.name = action.payload.name;
    },
    clearSelectedCourse(state) {
      state.id = null;
      state.name = null;
    },
  },
});

export const { setSelectedCourse, clearSelectedCourse } = courseSlice.actions;

export default courseSlice.reducer; // This is selectedCourseReducer
