import { Box } from "..";
import Typography from "../typography";
import { themeBase } from "../../theme";

interface MetricProps {
  label: string;
  value: number;
  // total: number;
  color?: string;
  icon?: React.ReactNode; // Accept any React node as an icon
}

const Metric = ({ label, value, color, icon }: MetricProps) => (
  <Box
    sx={{
      alignItems: "center",
      p: 2,
      borderRadius: "12px",
      minWidth: 150,
      display: "grid",
      gap: 2,
      bgcolor: themeBase.colorSchemes.new_design.palette.white, // Light purple background
    }}
  >
    <Typography
      sx={{
        fontSize: "1rem",
        fontWeight: 600,
        color: themeBase.colorSchemes.new_design.palette.gray,
      }}
    >
      {label}
    </Typography>

    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
      }}
    >
      <Box>
        <Typography
          sx={{ fontSize: "2rem", fontWeight: 700, color: "#000000" }}
        >
          {/* {value}/{total} */}
          {value}
        </Typography>
        <Box
          sx={{
            height: "4px", // Line thickness
            width: "40px", // Line length
            backgroundColor:
              color || themeBase.colorSchemes.new_design.palette.purple, // Line color
            borderRadius: "2px", // Optional for rounded corners
            mt: 1, // Margin top for spacing
          }}
        />
      </Box>

      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          backgroundColor:
            color || themeBase.colorSchemes.new_design.palette.purple_light, // Line color
          width: "50px",
          height: "50px",
          borderRadius: "8px",
        }}
      >
        {icon || (
          <Typography
            sx={{ color: themeBase.colorSchemes.new_design.palette.purple }}
          >
            ✓
          </Typography>
        )}
      </Box>
    </Box>
  </Box>
);

export default Metric;
